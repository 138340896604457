var auth = JSON.parse(window.sessionStorage.getItem('@baltartec-auth'));
interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Cadastro',
    url:'/cadastro',
    icon: 'fa fa-address-book',
    children:[
      {
        name: 'Empresas',
        url: '/cadastro/empresa',
        icon: 'icon-briefcase'
      },
      {
        name: 'Usuários',
        url: '/cadastro/usuario',
        icon: 'icon-user'
      },
      {
        name: 'Equipamentos',
        url: '/cadastro/equipamento',
        icon: 'fa fa-truck'
      },
      {
        name: 'Modelo de Tanques',
        url: '/cadastro/modelo-tanque',
        icon: 'fa fa-cube'
      },
      {
        name: 'Postos de Abast.',
        url: '/cadastro/posto-abastecimento',
        icon: 'fa fa-list'
      },
      {
        name: 'Fornecedores',
        url: '/cadastro/fornecedor',
        icon: 'icon-people'
      },
      {
        name: 'Centro de Custo',
        url: '/cadastro/centro-custo',
        icon: 'fa fa-dollar'
      },
    ]
  },
  {
    name: 'Rotina',
    url:'/rotina',
    icon: 'fa fa-arrows',
    children:[
      {
        name: 'Entradas',
        url: '/rotina/entradas',
        icon: 'fa fa-plus-square'
      },
      {
        name: 'Abastecimento',
        url: '/rotina/abastecimento',
        icon: 'fa fa-adjust'
      },
      {
        name: 'Transfêrencia',
        url: '/rotina/transferencia',
        icon: 'fa fa-exchange'
      }
    ]
  },
  auth? auth["use_localizacao"]?{
    name: 'Localização',
    url:'/localizacao',
    icon: 'fa fa-location-arrow ',
    children:[
      {
        name: 'Atual',
        url: '/localizacao/atual',
        icon: 'fa fa-map-marker'
      },
      {
        name: 'Trajeto',
        url: '/localizacao/trajeto',
        icon: 'fa fa-map'
      }
    ]
  }:{}:{},
  {
    name: 'Manutenção',
    url:'/manutencao',
    icon: 'fa fa-cog',
    children: [
      {
        name: 'Dashboard',
        url: '/manutencao/dashboard',
        icon: 'fa fa-table'
      },
      {
        name: 'Equipamento',
        url: '/manutencao/equipamento',
        icon: 'fa fa-ambulance'
      },
      {
        name: 'Ordem de Serviço',
        url:'/manutencao/os',
        icon: 'fa fa-clipboard',
        children: [
          {
            name: 'Criar OS',
            url: '/manutencao/os/create',
            icon: 'fa fa-plus-square'
          },
          {
            name: 'Ordens de Serviço',
            url: '/manutencao/os/list',
            icon: 'fa fa-list'
          }
        ]
      }
    ]
  },
  {
    name: 'Análises',
    url:'/analise',
    icon: 'fa fa-bar-chart',
    children: [
      {
        name: 'Empresa',
        url: '/analise/empresa',
        icon: 'fa fa-industry',
        children: [
          {
            name: 'Entradas',
            url: '/analise/empresa/entradas',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Saidas',
            url: '/analise/empresa/saidas',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Transferências',
            url: '/analise/empresa/transferencias',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Dashboard Analítico',
            url: '/analise/empresa/dashboard-analitico',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Resumo Movimentos',
            url: '/analise/empresa/estoque-atual',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'U. Abastecimentos',
            url: '/analise/empresa/ultimos-abastecimentos',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Movimento',
            url: '/analise/empresa/movimento',
            icon: 'fa fa-asterisk'
          },
          // {
          //   name: 'Movimento Gráfico',
          //   url: '/analise/empresa/movimento-grafico',
          //   icon: 'fa fa-asterisk'
          // },
          {
            name: 'Movimento Diário',
            url: '/analise/empresa/movimento-diario',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Exportações',
            url: '/analise/empresa/exportacoes',
            icon: 'fa fa-asterisk'
          },
          // {
          //   name: 'Movimento Diário Gráfico',
          //   url: '/analise/empresa/movimento-diario-grafico',
          //   icon: 'fa fa-asterisk'
          // },
        ]
      },
      {
        name: 'Posto',
        url: '/analise/postos',
        icon: 'fa fa-tachometer',
        children: [
          {
            name: 'Entradas',
            url: '/analise/posto/entradas',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Saidas',
            url: '/analise/posto/saidas',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Transferências',
            url: '/analise/posto/transferencias',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Estoque Atual',
            url: '/analise/posto/estoque-atual',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Movimento',
            url: '/analise/posto/movimento',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Movimento Diário',
            url: '/analise/posto/movimento-diario',
            icon: 'fa fa-asterisk'
          },
          // {
          //   name: 'Movimento Diário Gráfico',
          //   url: '/analise/posto/movimento-diario-grafico',
          //   icon: 'fa fa-asterisk'
          // },
        ]
      },
      {
        name: 'Equipamento',
        url: '/analise/equipameto',
        icon: 'fa fa-truck',
        children: [
          {
            name: 'Abastecimento',
            url: '/analise/equipamento/abastecimento',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Dashboard Analítico',
            url: '/analise/equipamento/dashboard-analitico',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Abastecimento Individual Gráfico',
            url: '/analise/equipamento/abastecimento-individual',
            icon: 'fa fa-asterisk'
          },
          {
            name: 'Abastecimento Comparativo Gráfico',
            url: '/analise/equipamento/abastecimento-comparativo',
            icon: 'fa fa-asterisk'
          }
        ]
      }
    ]
  }
];
