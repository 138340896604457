import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { DashboardSensorComponent } from './views/dashboard-sensor/dashboard-sensor.component'
import { RegisterComponent } from './views/register/register.component';
import { AuthGuards } from './guard/auth/auth';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'dashboard-sensor',
    component: DashboardSensorComponent,
    data: {
      title: 'Dashboard Sensor'
    },
    canActivate: [AuthGuards]
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Início'
    },
    children: [
      {
        path: 'inicio',
        loadChildren: './views/home/home.module#HomeModule',
        canActivate: [AuthGuards]
      },
      {
        path: 'cadastro',
        loadChildren: './views/cadastro/cadastro.module#CadastroModule',
        canActivate: [AuthGuards]
      },
      {
        path: 'rotina',
        loadChildren: './views/rotina/rotina.module#RotinaModule',
        canActivate: [AuthGuards]
      },
      {
        path: 'localizacao',
        loadChildren: './views/localizacao/localizacao.module#LocalizacaoModule',
        canActivate: [AuthGuards]
      },
      {
        path: 'manutencao',
        loadChildren: './views/manutencao/manutencao.module#ManutencaoModule',
        canActivate: [AuthGuards]
      },
      {
        path: 'analise',
        loadChildren: './views/analise/analise.module#AnaliseModule',
        data: {
          authorities: ['ADMIN', 'SUP', 'ADMINSV', "ASSOC"],
        },
        canActivate: [AuthGuards],

      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
