import { AlertasService } from './../../services/alertas/alertas.service';
import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { RotinaService } from '../../services/rotina/rotina.service';
import { forkJoin, Observable } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;

  alertasEntrada = 0;
  alertasAbast = 0;
  alertasTrans = 0;

  divergenciaConsumo = 0;
  abastecimentoIncompleto = 0;
  abastecimentoMaiorCapacidade = 0;
  postoInconsistente = 0;
  outros = 0;

  total = 0;


  constructor(
    private authService: AuthService,
    private router: Router,
    private rotinaService: RotinaService,
    private alertService: AlertasService,
    @Inject(DOCUMENT) _document?: any
    ) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.getAlertas();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logount() {
    this.authService.logount().subscribe(
      (res) => {
        this.router.navigate(['/login']);
      },
      (error) => {
        this.router.navigate(['/login']);
      });
  }

  getAlertas() {
    this.requisicoesAlertas().subscribe(
      (alertas) => {
        console.log('alertas', alertas);
        console.log(alertas[0].total);
        this.divergenciaConsumo           = alertas[0].total;
        this.abastecimentoIncompleto      = alertas[1].total;
        this.abastecimentoMaiorCapacidade = alertas[2].total;
        this.postoInconsistente           = alertas[3].total;
        this.outros                  = alertas[4].total;

        for (const i of alertas) {
          this.total += i.total;
        }
      },
      (error) => {
        console.log('error alertas', error);
      }
    );
  }

  requisicoesAlertas(): Observable<any[]> {
    const dc  = this.alertService.getAlertasCount('DC');
    const ai  = this.alertService.getAlertasCount('AI');
    const amc = this.alertService.getAlertasCount('AMC');
    const pei = this.alertService.getAlertasCount('PEI');
    const o = this.alertService.getAlertasCount('O');


    return forkJoin([dc, ai, amc, pei, o]);
  }
}
