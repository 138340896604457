import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_SENSOR_URL } from '../Api/api';
@Injectable({
  providedIn: 'root'
})
export class DashboardSensorService {

  constructor(private http: HttpClient) { }

  getVolume(bd) {
    return this.http.get(`${SERVER_SENSOR_URL}?bd=${bd}&&getData`);
  }



}

