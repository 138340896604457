import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthGuards implements CanActivate {
  auth;
  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastrService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    this.auth = this.authService.getAuth();
    if (this.auth) {
      if (route.data['authorities']) {
        const permissoes = route.data['authorities'];
        return this.checkLogin(permissoes, state.url);
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }

  checkLogin(permissoes: string[], url: string): boolean {

    const {nivelAcesso} = JSON.parse(this.auth);
    const permissao = permissoes.filter(item => item.toLowerCase() === nivelAcesso.toLowerCase());
    if (permissao.length === 0) {
      this.toast.warning('Você não tem permissão para acessar!');
      this.router.navigate(['/inicio']);
      return false;
    } else {
      return true;
    }
  }
}
