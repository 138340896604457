import { PostoAbastecimento } from './../../models/postoAbastecimento';
import { Component, OnInit, NgZone } from '@angular/core';
import { Empresa } from '../../models/empresa';
import { Label, Color } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import { DashboardSensorService } from './../../services/dashboard-sensor/dashboard-sensor.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard-sensor.component.html',
  styleUrls: ['dashboard-sensor.component.scss']
})
export class DashboardSensorComponent{
// tslint:disable-next-line: member-ordering
  total;
  sensores;
  teste = "UNIMED CA"
  gaugeType = "arch";
  gaugeLabel = "Litros";
  gaugeAppendText = "L";
  max=1000;
  min=0;
  thresholdConfig = {
    '0': {color: 'red'},
    '500': {color: 'orange'},
    '750': {color: 'green'}
  };
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    elements : { line : { tension : 0 } },
    showLines:false,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#1976D2',
      backgroundColor: 'transparent',
    },
    {
      borderColor: '#D32F2F',
      backgroundColor: 'transparent',
    },
    {
      borderColor: '#FFC107',
      backgroundColor: 'transparent',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  unidades;
  unidadesTeste = [];
  locaisTeste = ["UNIMED CA"]
  unidadesT = [
    {
      data: "07/05/20",
      hora: "12:39:49",
      distancia: "-1"
    },
    {
      data: "07/05/20",
      hora: "15:39:49",
      distancia: "880"
    },
    {
      data: "07/05/20",
      hora: "18:20:19",
      distancia: "750"
    }];

  constructor(
    private authService: AuthService,
    private router: Router,
    private dSensorService: DashboardSensorService,
    public zone: NgZone
    ){
      this.unidadesTeste = this.getToList(JSON.parse(window.sessionStorage.getItem('@baltartec-auth')).sensores)
      console.log(this.unidadesTeste)
  }

  ngOnInit(): void {
  }
  getToList(locais){
    var unidades = [];
    locais.map(local => {
      this.dSensorService.getVolume(local.db).subscribe(
        (res) => {
          console.log(res)
          unidades.push(this.formateData(res,local.nome))
        },
        (error) => {
          console.log('error', error);
        }
      );
    })
    return unidades;
  }
  formateData(volumes, nome = "UNIMED CA"){
    var value = [];
    var label = [];
    volumes.map(volume => {
      value.push(this.convertAltToLitros(volume.distancia))
      label.push(`${volume.data}`)
    })
    console.log(typeof(value))
    return {
      "nome": nome,
      "volumes": volumes,
      "grafValue": [{data:value, label: "Volume"}],
      "grafLabel": label
    }
  }
  convertAltToLitros(vol){
    var v = parseFloat(vol)
    if(v < 0) return 1000
    return v
  }

  getVolume(db) {
    this.dSensorService.getVolume(db).subscribe(
      (res) => {
        console.log(res)
        this.unidades = res;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  logout(){
    this.authService.logount_sensor().subscribe(
      (res) => {
        this.router.navigate(['/login']);
      },
      (error) => {
        this.router.navigate(['/login']);
      });
  }

}
