import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Account } from '../../models/account';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';



@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent {

  account: Account = new Account();
  loading = false;

  constructor(
    private authService: AuthService,
    private toast: ToastrService,
    private router: Router) { }

  login() {
    this.loading = true;
    this.authService.login(this.account).then((result) => {
      console.log('resultado', result);
      this.loading = false;
      this.router.navigate(['/dashboard']);
    }).catch((error) => {
      this.authService.login_sensor(this.account).then((result) => {
        console.log('resultado', result);
        this.loading = false;
        this.router.navigate(['/dashboard-sensor']);
      }).catch((error) => {
        console.log('error', error);
        this.loading = false;
        this.toast.error(error.error, 'Erro ao efetuar o login, verifique seus dados');
      })
    });
  }

}
