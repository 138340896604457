import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { SERVER_URL } from '../Api/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let auth: any   = this.authService.getAuth();
    auth            = auth != null ? JSON.parse(auth) : null;
    if (!!auth) {
      const url = `${SERVER_URL}/unauth/${auth.token}`;
      // tslint:disable-next-line: triple-equals
      if (request.url != url) {
        let newParams = new HttpParams({fromString: request.params.toString()});
        newParams = newParams.append('token', auth.token);
        request = request.clone({
          params: newParams
        });
      }
    }
    return next.handle(request);
  }
}
