import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
// bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
     ],
    exports: [
        CommonModule,
        FormsModule,
        DatePipe,
        ModalModule,
        PaginationModule,
        PopoverModule
    ],
    providers: [DatePipe],
})
export class SharedModule {}
