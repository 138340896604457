import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { Entrada } from '../../models/entrada';
import { Abastecimento } from '../../models/abastecimento';
import { Transferencia } from '../../models/transferencia';
import { Produto } from '../../models/produto';
import { Consumo } from '../../models/consumo';

@Injectable({
  providedIn: 'root'
})
export class RotinaService {

  constructor(private http: HttpClient) { }

  getEntrada() {
    return this.http.get(`${SERVER_URL}/entrada`);
  }

  saveEntrada(entrada: Entrada) {
    return this.http.post(`${SERVER_URL}/entrada`, entrada);
  }

  getEntradaById(id: number) {
    return this.http.get(`${SERVER_URL}/entrada/${id}`);
  }

  updateEntrada(entrada: Entrada) {
    return this.http.put(`${SERVER_URL}/entrada/${entrada.id}`, entrada);
  }

  deleteEntrada(id) {
    return this.http.delete(`${SERVER_URL}/entrada/${id}`);
  }

  getAbastecimento() {
    return this.http.get(`${SERVER_URL}/abastecimento`);
  }

  saveAbastecimento(abastecimento: Abastecimento) {
    return this.http.post(`${SERVER_URL}/abastecimento`, abastecimento);
  }

  deleteAbastecimento(id) {
    return this.http.delete(`${SERVER_URL}/abastecimento/${id}`);
  }

  exportarAbastecimento(id) {
    return this.http.delete(`${SERVER_URL}/abastecimento/${id}`);
  }

  getAbastecimentoById(id: number) {
    return this.http.get(`${SERVER_URL}/abastecimento/${id}`);
  }

  updateAbastecimento(abastecimento: Abastecimento) {
    return this.http.put(`${SERVER_URL}/abastecimento/web/${abastecimento.id}`, abastecimento);
  }

  exportAbastecimento(abastecimento: Abastecimento) {
    return this.http.post(`${SERVER_URL}/exportar/${abastecimento.id}`, abastecimento);
  }

  getTransferencia() {
    return this.http.get(`${SERVER_URL}/transferencia`);
  }

  saveTranferencia(transferencia: Transferencia) {
    return this.http.post(`${SERVER_URL}/transferencia`, transferencia);
  }

  updateTransferencia(transferencia: Transferencia) {
    return this.http.put(`${SERVER_URL}/transferencia/${transferencia.id}`, transferencia);
  }

  getTransferenciaById(id: number) {
    return this.http.get(`${SERVER_URL}/transferencia/${id}`);
  }

  getConsumo(id, numFinal, quantidade){
    let p = new HttpParams();
    p = p.append("id",id);
    p = p.append("numFinal",numFinal);
    p = p.append("quantidade",quantidade);
    console.log(p)
    return this.http.get<Consumo>(`${SERVER_URL}/consumo`, { params: p, observe: 'response' });
  }

  // alertas
  getAlertaEntrada() {
    return this.http.get<Entrada[]>(`${SERVER_URL}/entrada/notify`);
  }

  getAlertaAbastecimento() {
    return this.http.get<Abastecimento[]>(`${SERVER_URL}/abastecimento/notify`);
  }

  getCountAlertaAbastecimento() {
    return this.http.get<Abastecimento[]>(`${SERVER_URL}/abastecimento/count_notify`);
  }

  getAlertaTransferencia() {
    return this.http.get<Transferencia[]>(`${SERVER_URL}/transferencia/notify`);
  }

  saveProduto(produto: Produto) {
    return this.http.post(`${SERVER_URL}/produto`, produto);
  }

  getProdutos() {
    return this.http.get<Produto[]>(`${SERVER_URL}/produto`);
  }
}
