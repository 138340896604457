import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_URL } from '../Api/api';
import { Auth } from '../../models/auth';
import { Account } from '../../models/account';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(account: Account) {
    return new Promise((resolve, reject) => {
      this.http.post(`${SERVER_URL}/auth`, account).subscribe(
        (res: Auth) => {
          if (res.token) {
            this.setAuth(res);
            resolve(res);
          } else {
            reject(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  login_sensor(account: Account) {
    return new Promise((resolve, reject) => {
      this.http.post(`${SERVER_URL}/auth-sensor`, account).subscribe(
        (res: Auth) => {
          if (res.token) {
            this.setAuth(res);
            resolve(res);
          } else {
            reject(res);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  logount() {
    const auth = JSON.parse(this.getAuth());
    this.deletAuth();
    return this.http.get(`${SERVER_URL}/unauth/${auth.token}`);
  }

  logount_sensor() {
    const auth = JSON.parse(this.getAuth());
    this.deletAuth();
    return this.http.get(`${SERVER_URL}/unauth-sensor/${auth.token}`);
  }

  getAuth() {
    return window.sessionStorage.getItem('@baltartec-auth');
  }

  setAuth(auth: Auth) {
    window.sessionStorage.setItem('@baltartec-auth', JSON.stringify(auth));
  }

  getNivelAcesso()  {
    const auth = this.getAuth();
    if ( auth === null) {
      return null;
    } else {
      return JSON.parse(auth).nivelAcesso;
    }
  }

  deletAuth() {
    window.sessionStorage.removeItem('@baltartec-auth');
  }
}
