import { SERVER_URL } from './../Api/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  constructor(private http: HttpClient) { }
  // Divergências no consumo = DC
  // Abastecimento Incompleto = AI
  // Abastecimento maior que capacidade = AMC
  // Posto com estoque inconsistente = PEI
  // Justificado = JUS
  getAlertas(tipo, page=0, limit=10) {
    return this.http.get(`${SERVER_URL}/notify?tipoJustificativa=${tipo}`, {params: this.removeNullValues({"page": page, "limit": limit})});
  }

  getAlertasCount(tipo) {
    return this.http.get<any>(`${SERVER_URL}/abastecimento/count_notify?tipoJustificativa=${tipo}`);
  }

  private removeNullValues(obj: any) {
    // Percorre as propriedades do objeto
    for (let key in obj) {
      if (obj[key] === null || obj[key] === "") {
        // Remove a propriedade se o valor for null
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        // Se a propriedade for um objeto, chama a função recursivamente
        this.removeNullValues(obj[key]);
        // Se o objeto se tornou vazio, remove ele também
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }
    return obj;
  }
}
